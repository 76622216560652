<template>
  <!-- 隐私政策 -->
  <div class="container">
    <p>【审慎阅读】为切实保护用户个人隐私，优化用户体验，易服立达（以下简称“我们”，指北京易服立达科技有限公司）根据现行法规及政策，制定《易服立达隐私政策》，并特别提示：希望您在使用易服立达及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。</p>
    <p>我们的产品基于DCloud uni-app(5+ App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（IMEI/android ID/DEVICE_ID/IDFA、SIM 卡 IMSI 信息、OAID）以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。</p>
    <p>【适用范围】本隐私政策适用于您通过易服立达应用程序、网站、供第三方网站和应用程序使用的易服立达软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用我们的产品和服务。</p>
    <p>下文将帮您详细了解我们如何收集、使用、存储、传输、共享、转让（如适用）与保护个人信息；帮您了解查询、访问、删除、更正、撤回授权个人信息的方式。其中，有关您个人信息权益的条款重要内容我们已用加粗形式提示，请特别关注。</p>
    <p>1. 我们如何收集和使用个人信息</p>
    <p>2. 我们如何使用 cookie 等同类技术</p>
    <p>3. 我们如何共享、转让、公开披露个人信息</p>
    <p>4. 我们如何存储个人信息</p>
    <p>5. 我们如何保护个人信息的安全</p>
    <p> 6. 管理您的个人信息</p>
    <p>7. 未成年人使用条款</p>
    <p>8. 隐私政策的修订和通知</p>
    <p>9. 联系我们</p>
    <p>1. 我们如何收集和使用个人信息</p>
    <p>我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：</p>
    <p>1.1 注册、登录、认证</p>
    <p>1.1.1 注册、登录</p>
    <p>1.1.1.1 当您注册、登录易服立达及相关服务时，您可以通过手机号创建账号，并且您可以完善相关的网络身份识别信息（头像、昵称、密码），收集这些信息是为了帮助您完成注册。您还可以根据自身需求选择填写性别、生日、地区及个人介绍来完善您的信息。</p>
    <p>1.1.1.2 您也可以使用第三方账号登录并使用，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与易服立达账号绑定，使您可以直接登录并使用本产品和相关服务。</p>
    <p>1.1.2 认证</p>
    <p>在您使用身份认证、接单、发单等功能或服务时，根据相关法律法规，您可能需要提供您的真实身份信息（真实姓名、身份证号码、电话号码）以完成实名验证。 这些信息属于个人敏感信息，您可以拒绝提供，但您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。</p>
    <p>1.2 资讯浏览</p>
    <p>1.2.1 易服立达可能会向您推荐更感兴趣的信息，为实现这一功能，我们可能会收集必要的日志信息。</p>
    <p>1.2.2 用于信息展示的日志信息包括：</p>
    <p>您操作、使用的行为信息：点击、关注、收藏、搜索、浏览、分享</p>
    <p>您主动提供的信息：反馈、发布、点赞、评论</p>
    <p>地理位置信息：GPS 信息、WLAN 接入点、蓝牙和基站等传感器信息</p>
    <p>1.2.3 GPS 地理位置是敏感个人信息，若您拒绝提供，我们将不会根据 GPS 信息向您推荐信息，且不会影响易服立达其他功能的正常使用。但如果您进行了接单，为确认您切实到达客户现场并进行了服务，我们会记录您进行到场签到等订单执行步骤时的定位信息，若您拒绝提供，则无法完成订单的执行，从而获得收入。</p>
    <p>1.2.4 我们会基于 IP 地址对应的城市向您展示、推荐您所在地区的本地相关信息。</p>
    <p>1.2.5 我们收集、使用的上述信息进行了去标识化处理，数据分析仅对应特定的、无法直接关联您身份的编码，不会与您的真实身份相关联。</p>
    <p>1.3 信息发布</p>
    <p>1.3.1 您发布内容、评论、提问或回答时，我们将收集您发布的信息，并展示您的昵称、头像、发布内容。</p>
    <p>1.3.2 您使用上传图片、发布音视频功能时，我们会请求您授权相机、照片、麦克风权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用易服立达的其他功能。</p>
    <p>1.3.3 您发布信息并选择显示位置时，我们会请求您授权地理位置权限，并收集与本服务相关的位置信息。您如果拒绝授权提供精确地理位置信息，将无法使用此功能，但不影响您正常使用易服立达的其他功能。</p>
    <p>1.3.4 用户因使用我们的产品或者服务而被我们收集的信息，例如其他用户发布的信息中可能含有您的部分信息（如：在评论、留言、发布图文、音视频中涉及到与您相关的信息）。</p>
    <p>1.4 搜索</p>
    <p>您使用“易服立达”的搜索服务时，我们会收集您的搜索关键字信息、日志记录。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地存储设备之中，并可向您展示搜索结果内容、搜索历史记录。</p>
    <p>1.5 安全运行</p>
    <p>1.5.1 安全保障功能</p>
    <p>我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务是我们的核心目标。为实现安全保障功能所收集的信息是必要信息。</p>
    <p>1.5.2 设备信息与日志信息</p>
    <p>1.5.2.1 为了保障软件与服务的安全运行，我们会在需要时收集您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、设备 MAC 地址、网络设备硬件地址、IP 地址、WLAN 接入点、蓝牙、基站、IMEI、IMSI、软件版本号、已安装的应用列表、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。</p>
    <p>1.5.2.2为了预防恶意程序及安全运营所必需，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。</p>
    <p>1.5.2.3 我们可能使用您的账户信息、设备信息、服务日志信息以及我们关联公司、合作方在获得您授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。</p>
    <p>1.6 收集、使用个人信息目的变更</p>
    <p>请您了解，随着我们业务的发展，可能会对“易服立达”的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。</p>
    <p>1.7 依法豁免征得同意收集和使用的个人信息</p>
    <p>请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：</p>
    <p>1.7.1 与国家安全、国防安全直接相关的；</p>
    <p>1.7.2 与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>1.7.3 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
    <p>1.7.4 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p>1.7.5 所收集的您的个人信息是您自行向社会公众公开的；</p>
    <p>1.7.6 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p>1.7.7 根据您的要求签订或履行合同所必需的；</p>
    <p>1.7.8 用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；</p>
    <p>1.7.9 为合法的新闻报道所必需的；</p>
    <p>1.7.10 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
    <p>1.7.11 法律法规规定的其他情形。</p>
    <p>特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。</p>
    <p>2. 对 Cookie 和同类技术的使用</p>
    <p>Cookie 和同类技术是互联网中普遍使用的技术。当您使用易服立达及相关服务时，我们可能会使用相关技术向您的设备发送一个或多个 Cookie 或匿名标识符，以收集和存储您访问、使用本产品时的信息。我们承诺，不会将 Cookie 用于本隐私政策所述目的之外的任何其他用途。我们使用 Cookie 和同类技术主要为了实现以下功能或服务：</p>
    <p>2.1 保障产品与服务的安全、高效运转</p>
    <p>我们可能会设置认证与保障安全性的 cookie 或匿名标识符，使我们确认您是否安全登录服务，或者是否遇到盗用、欺诈及其他不法行为。这些技术还会帮助我们改进服务效率，提升登录和响应速度。</p>
    <p>2.2 帮助您获得更轻松的访问体验</p>
    <p>2.2.1 使用此类技术可以帮助您简化重复操作的步骤和流程（如：注册、登录）</p>
    <p>2.2.2 使用此类技术可以帮助您简化重复填写个人信息、输入搜索内容的步骤和流程（如：记录搜索、表单填写）。</p>
    <p>2.3 为您推荐、展示、推送您可能感兴趣的内容或账号</p>
    <p>2.3.1 我们可能会利用 Cookie 和同类技术了解您的偏好和使用习惯，进行数据分析，以改善产品服务、推荐用户感兴趣的信息或功能，并优化您对广告的选择。</p>
    <p>2.3.2 在易服立达的分享页中，我们可能会使用 cookie 对浏览活动进行记录，用于向您推荐信息和排查崩溃、延迟的相关异常情况以及探索更好的服务方式。</p>
    <p>3. 我们如何共享、转让、公开披露个人信息</p>
    <p>3.1 共享</p>
    <p>3.1.1 实现功能或服务的共享信息</p>
    <p>我们的产品中可能会包含第三方 SDK（Software Development Kit）或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息（如以嵌入代码、插件等形式）。</p>
    <p>我们使用的 SDK 包括以下类型：</p>
    <p>1、 当您在使用易服立达中由我们的关联方、第三方提供的功能，或者当软件服务提供商、智能设备提供商、系统服务提供商与我们联合为您提供服务时我们会将去标识化后的个人信息与这些关联方、第三方共享，进行综合统计并通过算法做特征与偏好分析，形成间接人群画像，用以向您进行推荐、展示或推送您可能感兴趣的信息，或者推送更适合您的特定功能、服务或商业广告。</p>
    <p>2、 登录第三方账号：当您使用易服立达账号登录第三方产品时，经过您的同意，我们会将您的昵称、头像及其他您授权的信息与您登录的第三方产品共享。</p>
    <p>3、 小程序：当您使用小程序时，未经您同意，我们不会向这些开发者、运营者共享您的个人信息。</p>
    <p>4、 地理位置服务：当您使用地理位置相关服务时，我们会将 GPS 信息与位置服务提供商（高德地图）进行共享以便可以向您返回位置结果。GPS 信息是敏感个人信息，拒绝提供，仅会影响地理位置服务功能，但不影响其他功能的正常使用。</p>
    <p>5、 支付功能：支付功能由与我们合作的第三方支付机构向您提供服务。第三方支付机构可能需要收集您的姓名、** 身份证号、** 银行卡类型及卡号、有效期及手机号码。身份证号、银行卡号、有效期及手机号码 是个人敏感信息，这些信息是支付功能所必需的信息，拒绝提供将导致您无法使用该功能，但不影响其他功能的正常使用。</p>
    <p>我们在下表中对在易服立达中接入的相关第三方 SDK 在目录中列明：</p>
    <p>1、百度 SDK</p>
    <p>应用场景：人脸采集功能</p>
    <p>SDK 提供方：百度</p>
    <p>隐私政策：https://ai.baidu.com/ai-doc/REFERENCE/Vkdygjliz</p>
    <p>2、高德 SDK</p>
    <p>应用场景：地图定位功能</p>
    <p>SDK 提供方：高德</p>
    <p>隐私政策：https://lbs.amap.com/pages/privacy/</p>
    <p>2、微信 SDK</p>
    <p>应用场景：微信好友和微信朋友圈分享功能</p>
    <p>SDK 提供方：腾讯</p>
    <p>隐私政策：https://privacy.qq.com/</p>
    <p>3、QQSDK</p>
    <p>应用场景：QQ 分享功能</p>
    <p>SDK 提供方：腾讯</p>
    <p>隐私政策：https://privacy.qq.com/</p>
    <p>4、腾讯云 SDK</p>
    <p>应用场景：通知推送功能</p>
    <p>SDK 提供方：腾讯</p>
    <p>隐私政策：https://cloud.tencent.com/document/product/301/11470</p>
    <p>5、支付宝 SDK</p>
    <p>应用场景：支付宝支付功能</p>
    <p>SDK 提供方：阿里巴巴</p>
    <p>隐私政策：https://render.alipay.com/p/c/k2cx0tg8</p>
    <p>6、友盟 SDK</p>
    <p>应用场景：统计和分析数据</p>
    <p>SDK 提供方：友盟</p>
    <p>隐私政策：https://www.umeng.com/policy</p>
    <p>7、环信 SDK</p>
    <p>应用场景：即时沟通功能</p>
    <p>SDK 提供方：环信</p>
    <p>隐私政策：https://www.easemob.com/protocol</p>
    <p>第三方服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。为了最大程度保障您的信息安全，我们建议您在使用任何第三方 SDK 类服务前先行查看其隐私条款。为保障您的合法权益，如您发现这等 SDK 或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系。</p>
    <p>3.1.2 实现安全与分析统计的共享信息</p>
    <p>3.1.2.1 保障使用安全：我们非常重视账号与服务安全，为保障您和其他用户的账号与财产安全，使您和我们的正当合法权益免受不法侵害，我们和关联公司或服务提供商可能会共享必要的设备、账号及日志信息。</p>
    <p>3.1.2.2 分析产品使用情况：为分析我们服务的使用情况，提升用户使用的体验，可能会与关联方或第三方共享产品使用情况（崩溃、闪退）的统计性数据，这些数据难以与其他信息结合识别您的个人身份。</p>
    <p>3.2 转让</p>
    <p>3.2.1 我们不会转让您的个人信息给任何其他第三方，除非征得您的明确同意。</p>
    <p>3.2.2 随着我们业务的持续发展，我们将有可能进行合并、收购、资产转让，您的个人信息有可能因此而被转移。在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准要求继受方保护您的个人信息，否则我们将要求继受方重新征得您的授权同意。</p>
    <p>3.3 披露</p>
    <p>3.3.1 我们不会公开披露您的信息，除非遵循国家法律法规规定或者获得您的同意。我们公开披露您的个人信息会采用符合行业内标准的安全保护措施.</p>
    <p>3.3.2 对违规账号、欺诈行为进行处罚公告时，我们会披露相关账号的信息。</p>
    <p>3.3.3 当您授权游戏或小程序后，您和好友的头像、昵称将会在排名及相关功能中展示。</p>
    <p>3.4 依法豁免征得同意共享、转让、公开披露的个人信息</p>
    <p>请您理解，在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意：</p>
    <p>3.4.1 与国家安全、国防安全直接相关的；</p>
    <p>3.4.2 与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>3.4.3 与犯罪侦查、起诉、审判和判决执行等直接相关的；</p>
    <p>3.4.4 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p>3.4.5 您自行向社会公众公开的个人信息；</p>
    <p>3.4.6 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
    <p>4. 我们如何存储个人信息</p>
    <p>4.1 存储地点</p>
    <p>我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。</p>
    <p>4.2 存储期限</p>
    <p>我们仅在为提供“易服立达”及服务之目的所必需的期间内保留您的个人信息：您发布的信息、评论、点赞及相关信息，在您未撤回、删除或未注销账号期间，我们会保留相关信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。</p>
    <p>5. 我们如何保护个人信息的安全</p>
    <p>5.1 我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。</p>
    <p>5.2 我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。</p>
    <p>5.3 我们会建立专门的安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。</p>
    <p>5.4 尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。</p>
    <p>5.5 您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码及相关个人信息透露给他人。</p>
    <p>5.6 我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。</p>
    <p>5.7 您一旦离开“易服立达”及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在易服立达及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于“易服立达”的链接或引导。</p>
    <p>6. 管理您的个人信息</p>
    <p>我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查询、访问、修改、删除、撤回同意授权、注销账号、投诉举报以及设置隐私功能的相关权利，以使您有能力保障您的隐私和信息安全。</p>
    <p>6.1 自主选择控制个性化推荐信息</p>
    <p>6.1.1 接收信息的自主可控</p>
    <p>6.1.1.1 自主决定接收推送资讯： 我们的信息推送和推荐功能可能会根据信息系统、算法在内的自动化决策机制做出。我们不断完善与探索推荐系统优化方案的同时，在审核层面也采取了严格的审核策略。特别地，为保障您接收信息的自主性，当您对我们推送的信息不感兴趣或希望减少某些信息推荐时，您可以使用手机系统提供的消息通知管理功能，控制是否接收我们推送的咨询。</p>
    <p>6.1.1.2 自主订阅所需的资讯： 在关注频道中会根据您主动选择关注的账号、信息进行展示，您可以自主选择关注或取消关注。</p>
    <p>6.1.2 程序化广告推送</p>
    <p>6.1.2.1 自主决定接收程序化广告信息： 程序化广告是广告展示技术之一，由广告主根据您在其应用内的偏好进行智能推荐。如易服立达提供广告功能，则将仅提供程序化广告对接技术，不会获取您在其他应用内的行为或个人数据，您可以在 app 中选择关闭或开启是否接收程序化广告，当您关闭后您看到的广告数量将保持不变，但广告的相关度会降低。</p>
    <p>6.1.2.2 自主进行广告兴趣管理： 为了帮助您管理广告兴趣标签，您可以在 app 中查询、修改、添加、删除您的广告兴趣标签。删除相关标签后，系统将减少此类广告的推送。同时，您也可以添加系统预制的广告兴趣标，添加后会增加相关广告的推送。</p>
    <p>6.1.2.3 广告信息退订： 通过回复“TD”退订我们发送您的营销短信。</p>
    <p>6.2 改变或撤回授权范围</p>
    <p>6.2.1 改变或撤回敏感权限设置</p>
    <p>6.2.1.1 您可以在设备本身操作系统中关闭 GPS 地理位置、通讯录、摄像头、麦克风、相册权限改变同意范围或撤回您的授权。撤回授权后我们将不再收集与这些权限相关信息。</p>
    <p>6.2.1.2 当您使用小游戏、小程序后，您可以在小游戏、小程序界面中管理小程序、小游戏获得的应用权限，您可以撤回对小程序、小游戏的应用权限授权。</p>
    <p>6.2.3 改变或撤回授权的信息处理</p>
    <p>特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响公司此前基于您的授权而开展的个人信息处理。</p>
    <p>6.3 访问、删除、更正您的个人信息</p>
    <p>6.3.1 访问个人账号信息</p>
    <p>您可以查询、访问您的头像、用户名、简介、性别、生日、地区，您可以在易服立达的“个人中心”中进行查询、访问。</p>
    <p>6.3.2 查询访问、更改、删除您的收藏、点赞、浏览记录、阅读历史记录、搜索历史历史记录</p>
    <p>6.4 注销账号</p>
    <p>您进入“个人中心”—申请注销账号。在您注销账号前，我们将验证您的个人身份、安全状态、设备信息等。我们将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。</p>
    <p>6.5 访问隐私政策</p>
    <p>a. 您可以在注册页面，查看本隐私政策的全部内容。</p>
    <p>b. 请您了解，本隐私政策中所述的“易服立达”及相关服务可能会根据您所使用的手机型号、系统版本、软件应用程序版本、移动客户端等因素而有所不同。最终的产品和服务以您所使用的“易服立达”软件及相关服务为准。</p>
    <p>7. 未成年人条款</p>
    <p>7.1 若您是未满 18 周岁的未成年人，在使用“易服立达”及相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。</p>
    <p>7.2 我们根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、储存、共享、转让或披露未成年人的个人信息；如果我们发现在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。</p>
    <p>7.3  若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过公司本隐私政策公示的联系方式与我们联系。</p>
    <p>8. 隐私政策的修订和通知</p>
    <p>8.1 为了给您提供更好的服务，易服立达及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。</p>
    <p>8.2 本隐私政策更新后，我们会在易服立达发出更新版本，并在更新后的条款生效前通过官方网站公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。</p>
    <p>9. 联系我们</p>
    <p>如对本隐私政策内容有任何疑问、意见或建议，您可通过登录“易服立达”客户端内的“意见反馈”页面与我们联系。</p>
    <p>发布日期：2022年 10 月 01 日</p>
    <p>生效日期：2022 年 11月 01 日</p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  data() {
    return {
      
    };
  },
  created() {},
  methods: {
   
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 16px;
  .indent{
    text-indent: 1.5em;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #333;
    margin-bottom: 16px;
    text-align: justify;
  }
  .bold {
    font-weight: bold;
  }
  .underline{
    text-decoration: underline;
  }
}
 
</style>
